<template>
  <div id="app">
    <error-boundary>
      <app-loader />
      <app-themes>
        <notifications classes="custom_notification" group="error" position="bottom right" />
        <app-header v-if="showHeader" :isDemo="isDemo" />
        <main>
          <transition name="fade" mode="out-in" appear class="default-transition">
            <router-view />
          </transition>
        </main>
        <app-footer />
        <vue-progress-bar></vue-progress-bar>
      </app-themes>
    </error-boundary>
    <component v-if="mathjaxUrl" :src="mathjaxUrl" :is="'script'"></component>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import ErrorBoundary from '@/components/common/ErrorBoundary.vue';
import AppHeader from '@/layout/AppHeader.vue';
import AppFooter from '@/layout/AppFooter.vue';
import AppThemes from '@/AppThemes.vue';
import AppLoader from '@/layout/AppLoader.vue';
import type { ThemeConfig } from '@/plugins/config';

@Component({
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
    ErrorBoundary,
    AppThemes,
    AppLoader,
  },
})
export default class App extends Vue {
  public currentPath: string = '/';
  public isDemo: boolean = false;
  public hasHeader: boolean = false;
  public isLogin: boolean = false;

  @State('isCompleted', { namespace: 'authModule' })
  private isCompleted!: boolean;

  @State('isLogged', { namespace: 'authModule' })
  private isLogged!: boolean;

  // FIXME: TS compiler is warning this method is never used.
  @State('accessToken', { namespace: 'authModule' })
  private accessToken!: boolean;

  @Getter('theme')
  public theme!: ThemeConfig;

  get showHeader() {
    return (this.isLogged && this.isCompleted) || this.hasHeader;
  }

  get mathjaxUrl() {
    if (!this.theme.name) return undefined;

    return this.theme.name === 'cert'
      ? 'https://cdn.certforschools.com/student-interface/mathjax/es5/tex-mml-chtml.js'
      : 'https://cdn.eprep.com/student-interface/mathjax/es5/tex-mml-chtml.js';
  }

  @Watch('$route.path', { immediate: true })
  public changeRoute(path: string): void {
    this.currentPath = path;
    this.isLogin = path === '/' || path === '/login' || path === '/forgot' || path === '/sso';
  }

  @Watch('$route.matched', { immediate: true })
  public changeRouteMatched(path: any): void {
    if (path.length) {
      this.hasHeader =
        (path[0]['name'] === 'practice' && (path.length > 1 && path[1]['name']) !== 'practice-login') ||
        path[0]['name'] === 'about';

      this.isDemo = path[0]['name'] === 'practice' && path[1]['name'] !== 'practice-login';
    }
  }
}
</script>
